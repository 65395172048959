<template>
  <div>
    <!-- {{ $t("role.role.tab_member.card_members.title") }} -->
    <div class="card-title">Membri</div>
    <div class="card-subtitle">
      Gestisci i diritti di accesso e condivisione delle persone che collaborano
      in questo ruolo
    </div>
    <br />

    <TB :tb_config="tb">
      <e-columns>
        <e-column
          field="id"
          headerText="ID"
          type="number"
          :isPrimaryKey="true"
          is-identity="true"
          :allowEditing="false"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="user.first_name"
          :headerText="$t('models.user.name')"
          clipMode="EllipsisWithTooltip"
          :isPrimaryKey="true"
          is-identity="true"
          :allowEditing="false"
        ></e-column>
        <e-column
          field="user.last_name"
          :headerText="$t('models.user.surnname')"
          clipMode="EllipsisWithTooltip"
          :isPrimaryKey="true"
          is-identity="true"
          :allowEditing="false"
        ></e-column>
        <e-column
          field="is_active"
          :headerText="$t('models.fyc.is_active')"
          displayAsCheckBox="true"
          type="boolean"
          editType="booleanedit"
        ></e-column>
        <e-column
          field="permissions.role_dc"
          :headerText="$t('models.fyc.permissions.role_dc')"
          clipMode="EllipsisWithTooltip"
          foreignKeyValue="label"
          :dataSource="permissionsData"
          foreignKeyField="value"
          defaultValue="1"
        ></e-column>
        <e-column
          field="permissions.role_pp"
          :headerText="$t('models.fyc.permissions.role_pp')"
          clipMode="EllipsisWithTooltip"
          foreignKeyValue="label"
          :dataSource="permissionsData"
          foreignKeyField="value"
          defaultValue="1"
        ></e-column>
        <e-column
          field="permissions.role_lg"
          :headerText="$t('models.fyc.permissions.role_lg')"
          clipMode="EllipsisWithTooltip"
          foreignKeyValue="label"
          :dataSource="permissionsData"
          foreignKeyField="value"
          defaultValue="1"
        ></e-column>
        <e-column
          field="permissions.role_os"
          :headerText="$t('models.fyc.permissions.role_os')"
          clipMode="EllipsisWithTooltip"
          foreignKeyValue="label"
          :dataSource="permissionsData"
          foreignKeyField="value"
          defaultValue="1"
        ></e-column>
        <e-column
          field="permissions.role_fin"
          :headerText="$t('models.fyc.permissions.role_fin')"
          clipMode="EllipsisWithTooltip"
          foreignKeyValue="label"
          :dataSource="permissionsData"
          foreignKeyField="value"
          defaultValue="1"
        ></e-column>
        <e-column
          field="permissions.role_exp"
          :headerText="$t('models.fyc.permissions.role_exp')"
          clipMode="EllipsisWithTooltip"
          foreignKeyValue="label"
          :dataSource="permissionsData"
          foreignKeyField="value"
          defaultValue="1"
        ></e-column>
        <!-- <e-column
          field="address"
          headerText="Indirizzo"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="city"
          headerText="Città"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="postal_code"
          headerText="CAP"
          clipMode="EllipsisWithTooltip"
          foreignKeyValue="code"
          :dataSource="CapData"
          foreignKeyField="id"
          editType="dropdownedit"
          :edit="postal_codeParams"
        ></e-column>
        <e-column
          field="country"
          headerText="Nazione"
          clipMode="EllipsisWithTooltip"
          foreignKeyValue="name"
          :dataSource="CountryData"
          foreignKeyField="code"
          editType="dropdownedit"
          :edit="countryParams"
        ></e-column>
        <e-column
          field="default_ship"
          headerText="X Spedizione"
          displayAsCheckBox="true"
          type="boolean"
          editType="booleanedit"
        ></e-column>

        <e-column
          field="default_bill"
          headerText="X Fatturazione"
          displayAsCheckBox="true"
          type="boolean"
          editType="booleanedit"
        ></e-column>
        <e-column
          field="ts_created"
          headerText="Creato"
          type="dateTime"
          :format="tsFormat"
          :allowEditing="false"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="ts_modified"
          headerText="Modificato"
          type="dateTime"
          :format="tsFormat"
          :allowEditing="false"
          clipMode="EllipsisWithTooltip"
        ></e-column> -->
      </e-columns>
    </TB>
  </div>
</template>
<script>
import { GetDataManager } from "../../../ds/index";
import { DataManager } from "@syncfusion/ej2-data";
import TB from "../../../common/TB";
export default {
  components: {
    TB,
  },
  data() {
    return {
      tb: {
        data: GetDataManager("auth_role_connection", [
          this.$store.state.role.id,
        ]),
        searchOptions: {
          fields: ["user.first_name", "user.last_name", "id"],
        },
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Normal",
        },
        toolbar: ["Add", "Edit", "Update", "Cancel"],
        toolbarOptions: [
          // {
          //   text: "Refresh",
          //   tooltipText: "Refresh data",
          //   prefixIcon: "e-expand",
          //   id: "refresh",
          // },
          "Add",
          "Edit",
          // "Delete",
          "Update",
          "Cancel",
          "Search",
          "ColumnChooser",
        ],
        // reload_on_save:true,
      },
      permissionsData: new DataManager([
        { value: 0, label: this.$t("models.fyc.permissions.options.v_0") },
        { value: 1, label: this.$t("models.fyc.permissions.options.v_1") },
        { value: 2, label: this.$t("models.fyc.permissions.options.v_2") },
      ]),
      tsFormat: { type: "dateTime", format: "dd/MM/yyyy HH:mm:ss" },
    };
  },
};
</script>
